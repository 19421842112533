import React from 'react';
import type { FC } from 'react';
import { css } from '@emotion/react';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';

const Page: FC = () => (
  <Layout>
    <SEO
      title="採用のご案内"
      description="獣医師、動物看護師を募集中です。"
      keywords="採用のご案内"
    />
    <article css={pageBaseStyle}>
      <h1>採用のご案内</h1>
      <div css={skewBorder}></div>
      <section>
        <p>
        当院では2023年11月から下記職種の採用を行っております。<br />私たち腫瘍診療チームの一員になり、スキルアップしませんか？
        </p>
      </section>
      <section>
        <h2>採用中の職種</h2>
        <p>
          <ul>
            <li>・獣医師</li>
            <li>・動物看護師</li>
          </ul>
        </p>
      </section>
      <section>
        <h2>獣医師</h2>
        <h3>【主な仕事内容】</h3>
        <p>
          <ul>
            <li>
              ・診察、画像検査、手術助手、化学療法
            </li>
            <li>・放射線療法の実施</li>
            <li>・緩和ケア、シニアケア</li>
            <li>
              ・臨床研究のためのデータ収集、解析
            </li>
          </ul>
        </p>
        <h3>【アピールポイント】</h3>
        <p>
          <ul>
            <li>
              ・日本獣医がん学会　腫瘍科認定医Ⅰ種・Ⅱ種の取得サポート
            </li>
            <li>・年間約<span css={emphasis}>500症例の初診腫瘍患者の診察を経験することが可能</span>です</li>
            <li>・幅広い腫瘍症例を経験し、学ぶことができます</li>
            <li>
              ・学会発表や論文投稿のサポートを行います
            </li>
            <li>
              ・清澄白河エリアであり徒歩圏内におしゃれなカフェが沢山あります
            </li>
          </ul>
        </p>
        <h3>【募集条件】</h3>
        <p>
          <ul>
            <li>
              ・獣医師　臨床経験　3年以上の方
            </li>
            <li>・腫瘍診療に興味がある方</li>
          </ul>
          ※日本獣医がん学会　<span css={emphasis}>腫瘍科2種認定医以上の資格を持つ方は優遇</span>
        </p>
        <h3>【勤務時間・曜日】</h3>
        <p>
          &nbsp;&nbsp;9: 00～18: 00（休憩時間 1時間）
        </p>
        <p>
          <ul>
            <li>
              ・シフト制
            </li>
            <li>・完全週休2日 祝日休み</li>
            <li>・緊急手術や重症患者のケアなどにより残業あり</li>
            <li>
              ・平均所定労働時間（1か月当たり）: 160時間
            </li>
          </ul>
        </p>
        <h3>【休暇・休日】</h3>
        <p>
          <ul>
            <li>
              ・完全週休2日　祝日休み
            </li>
            <li>・長期休暇取得　可能です</li>
            <li>・産休・育休制度あります</li>
          </ul>
        </p>
        <h3>【給与・待遇・福利厚生】</h3>
        <p>
          &nbsp;&nbsp;月給 ￥300,000円以上（経験年数・資格・実績などにより要相談）
          <br />
          &nbsp;&nbsp;前職の月給以上を保証
          <br />
          <br />
          &nbsp;&nbsp;※昇給年1回以上
          <br />
          &nbsp;&nbsp;※賞与　初年度年1回　それ以降　年2回（病院の業績による）
        </p>
        <p>
          <ul>
            <li>・社保完備</li>
            <li>・徒歩、自転車通勤可能な距離の場合　住宅手当支給</li>
            <li>・交通費支給（月額上限15,000円）</li>
            <li>・社員割引あり　自分のペットの診療費割引</li>
            <li>・自転車・バイク通勤可</li>
            <li>・転勤なし</li>
            <li>・制服貸与</li>
            <li>・セミナー、学会参加補助あり</li>
          </ul>
        </p>
        <h3>【エントリー方法】</h3>
        <p>
        &nbsp;&nbsp;エントリー専用メールアドレスに下記内容をご記載の上、送信してください。後日こちらからご連絡させていただきます。
        </p>
        <p>
          <ul>
            <li>・氏名</li>
            <li>・年齢　性別</li>
            <li>・獣医師　臨床経験年数</li>
            <li>・これまでの職歴</li>
            <li>・認定医資格などあれば記載</li>
            <li>・電話番号</li>
          </ul>
        </p>
        <p>
        &nbsp;&nbsp;エントリー専用メールアドレス
        <br />
        &nbsp;&nbsp;<span css={emphasis}>acct.cc.recruit@gmail.com</span>
        </p>
      </section>
      <section>
        <h2>愛玩動物看護師（正社員・パートタイマー）</h2>
        <h3>【主な仕事内容】</h3>
        <p>
          <ul>
            <li>・動物看護全般</li>
            <li>・診療補助、入院ケア、手術助手、栄養管理</li>
            <li>・シニアケア</li>
            <li>・受付、電話対応など</li>
          </ul>
          <span css={emphasis}>愛玩動物看護師　国家資格をお持ちの方は優遇</span>いたしますが、取得見込みの方、また動物病院での看護士業務の実務経験のある方でしたら、ご応募いただけます。
        </p>
        <h3>【アピールポイント】</h3>
        <p>
          <ul>
            <li>・<span css={emphasis}>愛玩動物看護士国家資格試験</span>のサポート・対策を行います</li>
            <li>・腫瘍専門獣医師をはじめ、経験豊富なスタッフが丁寧に教育指導します</li>
            <li>・犬と猫の腫瘍やシニアケアに興味のある方の活躍できる仕事場です</li>
            <li>・病院のとなりは公園と川に囲まれ、落ち着いた環境です</li>
            <li>・清澄白河エリアであり徒歩圏内におしゃれなカフェが沢山あります</li>
          </ul>
          ※院内・院外　全面禁煙
        </p>
        <h3>【募集条件】</h3>
        <p>
          &nbsp;&nbsp;応募条件:下記のいずれかに該当する方　どの条件でも大丈夫です
          <br />
          <br />
          &nbsp;&nbsp;１．動物看護専門学校・大学の既卒者（現在または過去に動物病院で勤務している方）
          <br />
          &nbsp;&nbsp;２．動物看護学校を卒業はしていないが、動物病院での実務経験のある方
        </p>
        <p>
          <ul>
            <li>・<span css={emphasis}>愛玩動物看護師　国家資格取得者　優遇</span></li>
            <li>・学歴不問</li>
            <li>・髪色、髪型自由</li>
          </ul>
        </p>
        <h3>【勤務時間・曜日】</h3>
        <p>
          &nbsp;&nbsp;〇正社員　9: 00～18: 00（休憩時間 1時間）
          <br />
          &nbsp;&nbsp;〇パートタイマー　勤務時間相談　週2日勤務から可能です
        </p>
        <p>
          <ul>
            <li>・シフト制</li>
            <li>・完全週休2日 祝日休み（日曜午後はシフト制で休み）<span css={emphasis}>→実質週休2.5日</span></li>
            <li>・平均所定労働時間（1か月当たり）: 160時間（正社員）</li>
            <li>・緊急手術や重症患者のケアなどにより残業あり（正社員）</li>
          </ul>
        </p>
        <h3>【休暇・休日】</h3>
        <p>
          <ul>
            <li>・完全週休2日　祝日休み（日曜午後はシフト制で休み）→実質週休2.5日</li>
            <li>・長期休暇取得　可能です</li>
            <li>・産休・育休制度あります</li>
          </ul>
        </p>
        <h3>【給与・待遇・福利厚生】</h3>
        <p>
          &nbsp;&nbsp;〇正社員：月給 ￥220,000以上（経験年数・実績により要相談）
          <br />
          &nbsp;&nbsp;　前職の月給以上を保証
          <br />
          &nbsp;&nbsp;〇パートタイマー：時給￥1,500円以上（経験年数・実績により要相談）
          <br />
          <br />
          &nbsp;&nbsp;※昇給年1回
          <br />
          &nbsp;&nbsp;※賞与　初年度年1回　それ以降　年2回（病院の業績による）
        </p>
        <p>
          <ul>
            <li>・社保完備</li>
            <li>・<span css={emphasis}>動物看護士国家資格試験</span>のサポート・対策を行います</li>
            <li>・徒歩、自転車通勤可能な距離の場合　住宅手当支給</li>
            <li>・交通費支給（月額上限15,000円）</li>
            <li>・経験豊富なスタッフによる研修あり</li>
            <li>・社員割引あり　自分のペットの診療費割引</li>
            <li>・自転車・バイク通勤可</li>
            <li>・転勤なし</li>
            <li>・制服貸与</li>
            <li>・セミナー、学会参加補助</li>
          </ul>
        </p>
        <h3>【エントリー方法】</h3>
        <p>
        &nbsp;&nbsp;エントリー専用メールアドレスに下記内容をご記載の上、送信してください。後日こちらからご連絡させていただきます。
        </p>
        <p>
          <ul>
            <li>・氏名</li>
            <li>・年齢　性別</li>
            <li>・動物看護師としての経験年数</li>
            <li>・これまでの職歴</li>
            <li>・愛玩動物看護師資格などあれば記載</li>
            <li>・電話番号</li>
          </ul>
        </p>
        <p>
        &nbsp;&nbsp;エントリー専用メールアドレス
        <br />
        &nbsp;&nbsp;<span css={emphasis}>acct.cc.recruit@gmail.com</span>
        </p>
      </section>
    </article>
  </Layout>
);
export default Page;

const pageBaseStyle = css`
  padding: 1rem 4rem;

  @media (max-width: 560px) {
    padding: 1rem !important; // TODO: index.scss撲滅 撲滅次第importantを削除する
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin-bottom: 1.3rem;
  }

  section {
    margin-top: 2rem;
  }

  h1 {
    text-align: center;
    margin-top: 2rem;
  }

  h2 {
    border-left: solid 0.8rem #80d8ff;
    margin-top: 4rem;
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
    clear: both;
  }

  h3 {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }

  ul li {
    padding-left: 1em;
    text-indent: -1em;
  }
`;

const skewBorder = css`
  width: 50%;
  height: 0.5rem;
  margin: 1rem auto 0;
  background-color: #80d8ff;
  background-image: repeating-linear-gradient(
    -45deg,
    #fff,
    #fff 7px,
    transparent 0,
    transparent 14px
  );

  @media (max-width: 560px) {
    width: 100%;
  }
`;

const emphasis = css`
  font-weight: bold;
  color: #e66a6a;
`;
